import React, { useState, useEffect } from 'react'
import Link from '../link'
import { languageNameRewriter, useLanguagesOfWebsite } from '../../../assets/functions'
import styled from 'styled-components'

const LanguageSwitcher = ({currPage}) => {

    const [relations, setRelations] = useState([])
    const langs = useLanguagesOfWebsite()

    useEffect(() => {
        if(!currPage.related_to || !currPage.related_page) {
            setRelations([])
        } else {
            setRelations([...currPage.related_to, ...currPage.related_page].map(r => ({...r, Jazyk: languageNameRewriter(r.Jazyk)})))
        }
    }, [currPage])

    const otherLangs = langs?.filter(l => languageNameRewriter(l.lang).code !== currPage.languageCode )

    return (
        <>
            {langs && langs.length === 2 && <SingleLanguage relations={relations[0]} otherLang={otherLangs[0]} />}
            {langs && langs.length > 2 && <MultipleLanguages relations={relations} otherLangs={otherLangs} currLang={currPage.Jazyk} />}
        </>
    )
}

export default LanguageSwitcher

// set local storage lang
const languageSwitcherFn = (lang) => {
    const target = lang.replace(/\//g, "") || "cs"
    localStorage.setItem("lang", target)
}

const SingleLanguage = ({relations, otherLang}) => {
    // There is no related page
    if(!relations) {
        const lang = languageNameRewriter(otherLang.lang)
        return (
            <li>
                <Link to={`/${lang.code}`} onClick={() => languageSwitcherFn(lang)}>{lang.name}</Link>
            </li>
        )
    }

    // There is a related page
    if(relations) {
        const urlLang = relations.Jazyk?.code === "" ? "/" : `/${relations.Jazyk?.code}/`
        const url = relations.TypStranky === "hlavni" ? "" : relations.slug

        return (
            <li>
                <Link to={`${urlLang}${url}`} onClick={() => languageSwitcherFn(urlLang)}>{relations.Jazyk?.name}</Link>
            </li>
        )
    }
}

const MenuDropdown = styled.div`
    position: relative;
    height: 2rem;

    @media (max-width: 799px) {
        height: unset;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: white;
        margin-top: 1rem;

        @media (min-width: 800px) {
            box-shadow: 0px 2px 26px rgba(0,0,0,0.1);
            height: unset;
            margin-left: 1rem;
            margin-top: 0;
        }


        li {
            height: 2rem;
            display: flex;
            align-items: center;
            padding: 1rem 0.3rem;

            @media (min-width: 800px) {
                width: 6rem;
                padding: 0.6rem;
            }

            &:first-child {
                color: rgba(0,0,0,0.6);
                justify-content: space-between;

                &::after {
                    content: "▼";
                    color: rgba(0,0,0,0.1)
                }

                &.up::after {
                    content: "▲"
                }
            }

            a {
                height: 2rem;
                width: 6rem;
                display: block;
                color: ${props => props.theme.colors.primaryColor};
                display: flex;
                align-items: center

            }
        }
    }
`

const MultipleLanguages = ({relations, otherLangs, currLang}) => {
    const [currLangRewr, setCurrLangRewr] = useState()
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        setCurrLangRewr(languageNameRewriter(currLang))
    }, [currLang])
    

    return (
        <MenuDropdown onClick={() => setShowMenu(!showMenu)}>
            <ul>
                <li disabled={true} className={showMenu ? "up" : ""}>{currLangRewr?.name}</li>
                {otherLangs.map(o => {
                    const lang = languageNameRewriter(o.lang)
                    return showMenu && <li key={lang.name}><ProcessLanguage lang={lang} relations={relations} /></li>
                })}
            </ul>
        </MenuDropdown>
    )
}

const ProcessLanguage = ({lang, relations}) => {
    const relation = relations.find(r => r.Jazyk.code === lang.code)

    if(!relation) return <Link to={`/${lang.code}`} onClick={() => languageSwitcherFn(lang.code)}>{lang.name}</Link>

    const urlLang = relation.Jazyk?.code === "" ? "/" : `/${relation.Jazyk?.code}/`
    const url = relation.TypStranky === "hlavni" ? "" : relation.slug

    return <Link to={`${urlLang}${url}`} onClick={() => languageSwitcherFn(lang.code)}>{lang.name}</Link>
}
