import React from 'react'
import Link from '../link'
import { StaticQuery, graphql } from 'gatsby'

const Logo = ({logo, currPage}) => {

    return (
        <StaticQuery 
            query={graphql`
                query LogoSiteNameQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <Link to={`/${currPage.languageCode || ''}`} className="navbar-brand" hreflang={currPage.languageCode === "" ? "cs" : currPage.languageCode}>
                    <img src={logo} alt={`${data.site.siteMetadata.title} logo`} style={{height: 50}}/>
                </Link>
            )}
        />

    )
}

export default Logo