/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "./../../assets/stylesheets/layout.scss"
import styled, {ThemeProvider} from 'styled-components'
import variables from "../../assets/stylesheets/variables"
import { setLightness } from 'polished'
import Transition from './transition'

const Body = styled.div`
  &.color {
    background-color: ${props => setLightness(0.99, props.theme.colors.primaryColor)};
  }
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
`

const Layout = ({ children, location }) => {

  const currPage = children.props.pageContext

  return (
    <>
      <Header currPage={currPage} />
      <ThemeProvider theme={variables()}>
        <Body>
          <Transition location={location}>
            <div className="outer-cover">
              <main>{children}</main>
            </div>
          </Transition>
        </Body>
      </ThemeProvider>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout