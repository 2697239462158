import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import variables from '../../assets/stylesheets/variables'


const FooterBox = styled.footer`
  margin-top: 3rem;
  background: white;
  color: ${props => props.theme.colors.primaryColor};
  padding: 1rem 40px;

`

const FooterInner = styled.div`
  justify-content: space-between;
  width: 100%;
  /* max-width: ${props => props.theme.sizes.width}px; */
  margin: 1rem auto;

  p {
    margin: 0;
    font-size: 0.75rem;

  }

`


const Footer = () => { 
  return (
    <ThemeProvider theme={variables()}>
      <FooterBox>
        <FooterInner>
          <p>&copy; umimplavat 2020</p>
        </FooterInner>
      </FooterBox>
    </ThemeProvider>
  )
}

export default Footer
