import { graphql, useStaticQuery } from "gatsby"

export default () => {
    const { siteData } = useStaticQuery(query)
    const { colors } = siteData
    // console.log(colors)

    return {
        colors,
        sizes: {
          width: 1120
        }
    }
}


const query = graphql`
  query colors {
    siteData: site {
      colors: siteMetadata {
        primaryColor
      }
    }
  }
`