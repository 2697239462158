import React, { useState, useEffect } from 'react';
import logo from './../../assets/images/logo.png'
import styled, { ThemeProvider } from 'styled-components';
import LanguageSwitcher from './header/languageSwitcher';
import Logo from './header/logo'
import Menu from './header/menu';
import variables from '../../assets/stylesheets/variables';

const Container = styled.div`
  @media (min-width: 800px) {
    display: flex;
  }
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.theme.sizes.width}px;
  margin: 0 auto;
`

const ToggleButton = styled.button`
  @media (min-width: 800px) {
    display: none;
  }
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  background-color: white;
  float: right;
  transform: translateY(25%);
  transition: background-color 0.3s ease;
  border-radius: 2px;

  &:hover {
    background-color: whitesmoke
  }

  div {
    height: 2px; 
    width: 100%;
    background-color: black;
    position: relative; 
    border-radius: 2px;
    
    &::before, &::after {
      content: "";
      border-radius: 2px;
      display: block; 
      position: absolute; 
      height: 2px; 
      width: 100%;
      background: black;
    }

    &::before {
      transform: translateY(-8px)
    }
    &::after {
      transform: translateY(8px)
    }
  }
`

const StyledNavbar = styled.nav`
    display: flex;
    position: fixed;
    padding: 1rem 40px;
    align-items: center;
    z-index: 69000;
    justify-content: space-between;
    left: 0%;
    right: 0;
    top: 0;
    transition: all 0.4s ease ;
    box-shadow: 0px 4px 10px rgba(0,0,0,0);
    background-color: white;
    
    &.navbar-has-shadow {
      box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
    }

    &.navbar-is-hidden {
      transition: all 0.4s ease 0.2s ;
      top: -120px
    }
  `

  const StyledList = styled.ul`

    @media (max-width: 799px) {
      height: 100%;
      max-height: 1600px;
      transition: max-height 0.5s ease;
      overflow: hidden;

      &.navbar-is-collapsed {
        max-height: 0px
      }

    }

    @media (min-width: 800px) {
      display: flex;
    }
    list-style-type: none;
    align-items: center;
    margin: 0; 
    padding: 0;

    & > li {
      @media (max-width: 799px) {
        margin-top: 1rem;
      }
      @media (min-width: 800px) {
        margin-left: 1rem;
      }
      
      a {
        color: inherit;
        opacity: 0.6;
        transition: opacity 0.3s ease;
        text-decoration: none;
        padding: 0.3rem;

        &:hover {
          opacity: 1
        }
      }
    }
  `

const Header = ({currPage}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [hasShadow, setHasShadow] = useState(false);
  const [prevDist, setPrevDist] = useState(0);

  const toggle = () => setIsOpen(!isOpen);


  const scrollDownOrUp = () => {
    const offset = window.pageYOffset
    offset < prevDist && !isVisible && setIsVisible(true)
    offset > prevDist && isVisible && setIsVisible(false)
    offset < 30 && setIsVisible(true)
    
    offset < 30 ? setHasShadow(false) : setHasShadow(true)

    setPrevDist(offset)
  }

  
  useEffect(() => {
    window.addEventListener("scroll", () => scrollDownOrUp())

    return () => {
      window.removeEventListener("scroll", () => scrollDownOrUp())
    }
  })

  return (
    <StyledNavbar className={`${isVisible ? "" : "navbar-is-hidden"} ${hasShadow ? "navbar-has-shadow" : ""}`} style={{
      top: isVisible ? 0 : -120,
    }}>
      <ThemeProvider theme={variables()}>
        <Container>
          <Logo logo={logo} currPage={currPage} />
          <ToggleButton onClick={() => toggle()}><div></div></ToggleButton>
          <StyledList className={isOpen ? "" : "navbar-is-collapsed"}>
            <Menu currPage={currPage} />
            <LanguageSwitcher currPage={currPage} />
          </StyledList>
        </Container>
      </ThemeProvider>
    </StyledNavbar>
  );
}

export default Header;