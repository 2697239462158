import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export function splitWords(text) {
    text = text.split(" ")

    console.log(text)

    let spannedText = <div></div>

    for(const word of text) {
        spannedText += <span>{word}</span>
        // console.log(word)
    }
    console.log(spannedText)
    return <>{spannedText}</>
}

export function languageNameRewriter(language) {
    switch(language) {
        case "english":
            return {code: "en", name: "English"};
        case "deutsch":
            return {code: "de", name: "Deutsch"};
        case "cesky": 
            return {code: "", name: "Česky"};
        default: 
            return {code: "", name: "Česky"};
    }
}

export function useLanguagesOfWebsite() {
    const {strapi, site} = useStaticQuery(graphql`
        query LanguageSwitcherQuery {
            site {
                siteMetadata {
                    domain
                }
            }
            strapi {
                domenas  {
                    Nazev
                    Stranky {
                        Jazyk
                        Status
                    }
                }
            }
        }
    `)
    
    const languageAmounts = strapi.domenas.find(d => d.Nazev === site.siteMetadata.domain)?.Stranky
    .filter(s => 
        (process.env.NODE_ENV === "development" && (s.Status === "publikovany" || s.Status === "navrh")) || 
        (s.Status === "publikovany")
    )
    .reduce((langs, currVal) => {
        if(!langs[currVal.Jazyk]) {
            langs[currVal.Jazyk] = 0
        }
        langs[currVal.Jazyk] += 1
        return langs
    }, {})

    return Object.entries(languageAmounts).map(l => {return {lang: l[0], amount: l[1]}})

}