import React from 'react'
import { Link as GatsbyLink } from "gatsby"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import { withTheme } from 'styled-components';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, target, theme, hreflang, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        // paintDrip
        // hex={theme.colors.primaryColor}
        {...other}
        hrefLang={hreflang}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target={target} rel={target === "_blank" ? "noopener noreferrer" : ""} {...other} hrefLang={hreflang}>
      {children}
    </a>
  )
}
export default withTheme(Link)
