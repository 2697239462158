import React, { useState, useEffect } from 'react'
import { languageNameRewriter } from '../../../assets/functions';
import Link from '../link'
import { StaticQuery, graphql } from 'gatsby';


const Menu = ({currPage}) => {
    return (
        <StaticQuery 
            query={graphql`
                query LayoutQuery {
                    site {
                        siteMetadata {
                            domain
                        }
                    }
                    strapi {
                        menus {
                            Titulek
                            domena {
                                Nazev
                            }
                            strankas {
                                Titulek
                                slug
                                MenuTitulek
                                TypStranky
                            }
                            Jazyk
                        }
                    }
                }
            `}
            render={data => <MenuContainer data={data} currPage={currPage} />}
        />
    )
}

export default Menu

const MenuContainer = ({data, currPage}) => {
    const [activeMenu, setActiveMenu] = useState();
    const [langCode, setLangCode] = useState();
    
    const { strapi, site } = data

    const { menus } = strapi
    
    useEffect(() => {
        setActiveMenu(menus.find(m => m.Jazyk === currPage.Jazyk && m.domena.Nazev === site.siteMetadata.domain))
    }, [currPage, menus, activeMenu, site])
    
    useEffect(() => {
        setLangCode(languageNameRewriter(currPage.Jazyk))
    }, [currPage])
    
    return (
        <>
            {activeMenu?.strankas.map((n,i) => 
                <li key={i}>
                    <Link to={`${langCode.code === "" ? "/" : `/${langCode.code}/`}${n.TypStranky === "hlavni" ? "" : n.slug}`} hreflang={langCode.code === "" ? "cs" : langCode.code}>
                        {n.MenuTitulek || n.Titulek}
                    </Link>
                </li>
            )}
        </>
    )
}